<template>
  <div>
    <v-card>
      <v-card-text>

           <v-row>
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="From"></DatePicker>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateTo" label="To"></DatePicker>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="request_type" :items="request_types" item-text="description_en" item-value="id" label="Request Type"></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="report_type" :items="report_types" item-text="name" item-value="id" label="Report Type"></v-select>
          </v-col>
        </v-row>

       


 

        <v-btn  color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>



      </v-card-text>
    </v-card>


      <v-row v-if="flag==1">
        <v-col  cols="12" sm="12" md="12">


<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.25in'>
      <td width=1058 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
      height:.25in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=960 valign=top style='width:720.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Time Analysis Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=1058 style='width:793.6pt;padding:0in 0in 0in 0in;height:9.0pt;
      min-width: 279.93mm'></td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:27.0pt'>
      <td width=1058 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 279.93mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=960 valign=top style='width:720.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 254.00mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: {{request_types.filter(o => o.id==request_type)[0].description_en}}, Report Type: {{report_types.filter(o => o.id==report_type)[0].name}} from {{dateFrom}} to {{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in;min-width: 279.93mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=1>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in;min-width: 279.93mm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:9.0pt'>
        <td width=1058 style='width:793.6pt;padding:0in 0in 0in 0in;height:
        9.0pt'></td>
       </tr>
       <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
        <td width=1058 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
        min-width: 279.93mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=660 style='width:495.1pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 174.63mm ; margin-left: auto;
  margin-right: auto;' cols=6>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=240 style='width:2.5in;padding:0in 0in 0in 0in;min-width: 63.50mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=84 style='width:63.0pt;padding:0in 0in 0in 0in;min-width: 22.23mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
          <td width=96 style='width:1.0in;padding:0in 0in 0in 0in;min-width: 25.40mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:27.0pt'>
          <td width=468 colspan=4 valign=top style='width:351.05pt;border:solid silver 1.0pt;
          border-bottom:solid black 1.0pt;mso-border-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:27.0pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Summary of {{report_types.filter(o => o.id==report_type)[0].name}} {{dateFrom}} - {{dateTo}}<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:27.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:solid silver 1.0pt;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:27.0pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=288 colspan=2 valign=top style='width:216.05pt;border-top:
          none;border-left:solid silver 1.0pt;border-bottom:none;border-right:
          solid silver 1.0pt;mso-border-top-alt:solid black 1.0pt;mso-border-top-alt:
          solid black 1.0pt;mso-border-left-alt:solid silver .25pt;mso-border-right-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=180 colspan=2 valign=top style='width:135.05pt;border-top:
          none;border-left:none;border-bottom:solid silver 1.0pt;border-right:
          solid silver 1.0pt;mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:
          solid silver .25pt;mso-border-alt:solid silver .25pt;mso-border-top-alt:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Current Period<o:p></o:p></span></b></p>
          </td>
          <td width=192 colspan=2 valign=top style='width:2.0in;border:none;
          border-right:solid silver 1.0pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Previous Period<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=288 colspan=2 valign=top style='width:216.05pt;border:solid silver 1.0pt;
          border-top:none;mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:
          solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'># of Requests<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border:solid silver 1.0pt;
          border-left:none;mso-border-left-alt:solid silver .25pt;mso-border-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:solid silver 1.0pt;
          border-left:none;mso-border-top-alt:solid silver .25pt;mso-border-bottom-alt:
          solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'># of Requests<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=288 colspan=2 valign=top style='width:216.05pt;border-top:
          none;border-left:solid silver 1.0pt;border-bottom:solid silver 1.0pt;
          border-right:none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:
          solid silver .25pt;mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:
          solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'># of Days Late<o:p></o:p></span></i></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border:none;border-bottom:
          solid silver 1.0pt;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:
          solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>On Time {{malek}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[0][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[0][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[0][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[0][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
                  <tr style='mso-yfti-irow:6;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>1 - 15 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[1][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[1][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[1][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[1][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>16 - 30 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[2][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[2][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[2][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[2][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>31 - 60 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[3][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[3][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[3][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[3][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>61 - 120 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[4][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[4][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[4][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[4][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>121 - 180 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[5][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[5][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[5][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[5][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>181 - 365 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[6][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[6][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[6][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[6][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>More than 365 Days<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[7][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[7][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[7][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-bottom-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[7][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:12;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Subtotal:<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[8][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[8][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[8][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[8][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:13;mso-yfti-lastrow:yes;height:.25in'>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          solid silver 1.0pt;border-bottom:solid silver 1.0pt;border-right:
          none;mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-left-alt:solid silver .25pt;mso-border-bottom-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=240 valign=top style='width:2.5in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid silver .25pt;mso-border-top-alt:solid silver .25pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><span
          style='font-size:9.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:
          "Times New Roman";color:black'>Total:<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[9][0]}}<o:p></o:p></span></b></p>
          </td>
          <td width=84 valign=top style='width:63.0pt;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[9][1]}}%<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-bottom-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[9][2]}}<o:p></o:p></span></b></p>
          </td>
          <td width=96 valign=top style='width:1.0in;border-top:none;
          border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{T1[9][3]}}%<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in;min-width: 50.80mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:27.0pt'>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:27.0pt'></td>
      <td width=672 style='width:504.05pt;padding:0in 0in 0in 0in;height:27.0pt;
      min-width: 177.80mm'></td>
      <td width=194 style='width:145.5pt;padding:0in 0in 0in 0in;height:27.0pt;
      min-width: 51.33mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateTo}}<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=672 valign=top style='width:504.05pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 177.80mm'></td>
      <td width=194 valign=top style='width:145.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 51.33mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 1 / 3<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:9.0pt'>
      <td width=864 colspan=2 valign=top style='width:648.1pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 228.60mm'></td>
      <td width=194 valign=top style='width:145.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 51.33mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>

</span></div>

</div>

<div>

<div>

<table v-if="report_type=='DX'" class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
 cols=1>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in;min-width: 279.93mm'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;
   min-width: 279.93mm'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in;min-width: 254.00mm'>
    <div>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:.25in'>
      <td width=1058 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
      height:.25in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=960 valign=top style='width:720.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Time Analysis Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=1058 style='width:793.6pt;padding:0in 0in 0in 0in;height:9.0pt;
      min-width: 279.93mm'></td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:27.0pt'>
      <td width=1058 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
      height:27.0pt;min-width: 279.93mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=960 valign=top style='width:720.1pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 254.00mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Request Type: {{request_types.filter(o => o.id==request_type)[0].description_en}}, Report Type: {{report_types.filter(o => o.id==report_type)[0].name}} from {{dateFrom}} to {{dateTo}}<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </div>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1'>
    <td style='padding:0in 0in 0in 0in;min-width: 88.90mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'
     cols=2>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in;min-width: 88.90mm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
        <td width=336 style='width:252.05pt;padding:0in 0in 0in 0in'></td>
        <td width=722 style='width:541.55pt;padding:0in 0in 0in 0in;min-width: 191.03mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1'>
        <td width=1058 colspan=2 valign=top style='width:793.6pt;padding:0in 0in 0in 0in;
        min-width: 279.93mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 279.93mm' cols=15>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=120 style='width:1.25in;padding:0in 0in 0in 0in;min-width: 31.75mm'></td>
          <td width=76 style='width:57.0pt;padding:0in 0in 0in 0in;min-width: 20.11mm'></td>
          <td width=180 style='width:135.05pt;padding:0in 0in 0in 0in;
          min-width: 47.63mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=71 style='width:53.25pt;padding:0in 0in 0in 0in;min-width: 18.79mm'></td>
          <td width=77 style='width:57.75pt;padding:0in 0in 0in 0in;min-width: 20.37mm'></td>
          <td width=50 style='width:37.5pt;padding:0in 0in 0in 0in;min-width: 13.23mm'></td>
          <td width=48 style='width:.5in;padding:0in 0in 0in 0in;min-width: 12.70mm'></td>
          <td width=48 style='width:.5in;padding:0in 0in 0in 0in;min-width: 12.70mm'></td>
          <td width=39 style='width:29.25pt;padding:0in 0in 0in 0in;min-width: 10.32mm'></td>
          <td width=37 style='width:27.75pt;padding:0in 0in 0in 0in;min-width: 9.79mm'></td>
          <td width=108 style='width:81.0pt;padding:0in 0in 0in 0in;min-width: 28.58mm'></td>
          <td width=49 style='width:36.75pt;padding:0in 0in 0in 0in;min-width: 12.96mm'></td>
          <td width=48 style='width:.5in;padding:0in 0in 0in 0in;min-width: 12.70mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.25in'>
          <td width=1058 colspan=15 valign=top style='width:793.6pt;border-top:
          solid black 1.0pt;border-left:solid black 1.0pt;border-bottom:none;
          border-right:solid silver 1.0pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Detailed Listing of Active Requests: {{dateFrom}} -
          {{dateTo}}<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=36 valign=top style='width:27.0pt;border-top:solid black 1.0pt;
          border-left:solid black 1.0pt;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-top-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          mso-border-right-alt:solid silver .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=120 valign=top style='width:1.25in;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid black 1.0pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=180 valign=top style='width:135.05pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=146 colspan=3 valign=top style='width:109.5pt;border-top:
          solid black 1.0pt;border-left:none;border-bottom:solid silver 1.0pt;
          border-right:solid silver 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Days<o:p></o:p></span></i></b></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=108 valign=top style='width:81.0pt;border-top:solid black 1.0pt;
          border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-top-alt:solid black 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=97 colspan=2 valign=top style='width:72.75pt;border-top:
          solid black 1.0pt;border-left:none;border-bottom:solid darkgray 1.0pt;
          border-right:solid black 1.0pt;mso-border-left-alt:solid silver .25pt;
          mso-border-top-alt:black 1.0pt;mso-border-left-alt:silver .25pt;
          mso-border-bottom-alt:darkgray .25pt;mso-border-right-alt:black 1.0pt;
          mso-border-style-alt:solid;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Pages<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:15.1pt'>
          <td width=36 valign=top style='width:27.0pt;border-top:none;
          border-left:solid black 1.0pt;border-bottom:solid black 1.0pt;
          border-right:solid silver 1.0pt;mso-border-left-alt:solid black 1.0pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=120 valign=top style='width:1.25in;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid black 1.0pt;mso-border-left-alt:solid black 1.0pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Request<o:p></o:p></span></i></b></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Source<o:p></o:p></span></i></b></p>
          </td>
          <td width=180 valign=top style='width:135.05pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Summary<o:p></o:p></span></i></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Complete Received<o:p></o:p></span></i></b></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Due Date<o:p></o:p></span></i></b></p>
          </td>
          <td width=77 valign=top style='width:57.75pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Date Closed<o:p></o:p></span></i></b></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border:none;border-bottom:
          solid black 1.0pt;mso-border-left-alt:solid silver .25pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Allowed<o:p></o:p></span></i></b></p>
          </td>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Taken<o:p></o:p></span></i></b></p>
          </td>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Overdue<o:p></o:p></span></i></b></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Exe<o:p></o:p></span></i></b></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Exc<o:p></o:p></span></i></b></p>
          </td>
          <td width=108 valign=top style='width:81.0pt;border-top:none;
          border-left:none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
          mso-border-left-alt:solid silver .25pt;mso-border-left-alt:solid silver .25pt;
          mso-border-bottom-alt:solid black 1.0pt;mso-border-right-alt:solid silver .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Decision communicated<o:p></o:p></span></i></b></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border:none;border-bottom:
          solid black 1.0pt;mso-border-left-alt:solid silver .25pt;padding:
          2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Review<o:p></o:p></span></i></b></p>
          </td>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Release<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tbody v-for="(i,OnTimeLate) in T2" :key="i">
         <tr style='mso-yfti-irow:4;height:.25in'>
          <td width=36 valign=top style='width:27.0pt;border:none;border-bottom:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=1022 colspan=14 valign=top style='width:766.6pt;border:
          none;border-bottom:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Requests Currently {{OnTimeLate}}<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:15.1pt' v-for="(e) in i" :key="e">
          <td width=36 valign=top style='width:27.0pt;border-top:none;
          border-left:solid darkgray 1.0pt;border-bottom:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-right-alt:solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{i.indexOf(e)+1}}<o:p></o:p></span></p>
          </td>
          <td width=120 valign=top style='width:1.25in;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{e.RequestDemande}}<o:p></o:p></span></p>
          </td>
          <td width=76 valign=top style='width:57.0pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{e.sourceE}}<o:p></o:p></span></p>
          </td>
          <td width=180 valign=top style='width:135.05pt;border:none;
          border-right:solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{e.summary}}<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.dateinfocomplete}}<o:p></o:p></span></p>
          </td>
          <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.datedue}}<o:p></o:p></span></p>
          </td>
       <td width=71 valign=top style='width:53.25pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.dateofclosure}}<o:p></o:p></span></p>
          </td>
          <td width=50 valign=top style='width:37.5pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.allowed_new}}<o:p></o:p></span></p>
          </td>
          <td width=48 valign=top style='width:.5in;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.taken_new}}<o:p></o:p></span></p>
          </td>
          <td width=48 valign=top style='width:.5in;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.ActiveOverdue}}<o:p></o:p></span></p>
          </td>
          <td width=39 valign=top style='width:29.25pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.Exe}}<o:p></o:p></span></p>
          </td>
          <td width=37 valign=top style='width:27.75pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.Exc}}<o:p></o:p></span></p>
          </td>
          <td width=108 valign=top style='width:81.0pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          8.0pt;font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
          color:black'>{{e.DispositionE}}<o:p></o:p></span></p>
          </td>
          <td width=49 valign=top style='width:36.75pt;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.pagesreviewed}}<o:p></o:p></span></p>
          </td>
          <td width=48 valign=top style='width:.5in;border:none;border-right:
          solid darkgray 1.0pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-right-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal align=right style='text-align:right;vertical-align:
          top'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
          mso-fareast-font-family:"Times New Roman";color:black'>{{e.pagesreleased}}<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:13;height:.25in'>
          <td width=36 valign=top style='width:27.0pt;border:solid darkgray 1.0pt;
          mso-border-alt:solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=376 colspan=3 valign=top style='width:282.05pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Total Requests Currently Late: <o:p></o:p></span></i></b></p>
          </td>
          <td width=142 colspan=2 valign=top style='width:106.5pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Decision Communicated<o:p></o:p></span></i></b></p>
          </td>
          <td width=175 colspan=3 valign=top style='width:131.25pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Decision Not Communicated<o:p></o:p></span></i></b></p>
          </td>
          <td width=124 colspan=3 valign=top style='width:93.0pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Pages Reviewed<o:p></o:p></span></i></b></p>
          </td>
          <td width=157 colspan=2 valign=top style='width:117.75pt;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Pages Released<o:p></o:p></span></i></b></p>
          </td>
          <td width=48 valign=top style='width:.5in;border:solid darkgray 1.0pt;
          border-left:none;mso-border-left-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:14;height:.25in'>
          <td width=36 valign=top style='width:27.0pt;border:solid darkgray 1.0pt;
          border-top:none;mso-border-top-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=376 colspan=3 valign=top style='width:282.05pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><span style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>{{periodrequests.length}}<o:p></o:p></span></b></p>
          </td>
          <td width=142 colspan=2 valign=top style='width:106.5pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=175 colspan=3 valign=top style='width:131.25pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center'><span
          style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=124 colspan=3 valign=top style='width:93.0pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Not Available<o:p></o:p></span></i></b></p>
          </td>
          <td width=157 colspan=2 valign=top style='width:117.75pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in'>
          <p class=MsoNormal align=center style='text-align:center;vertical-align:
          top'><b><i><span style='font-size:10.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Not Available<o:p></o:p></span></i></b></p>
          </td>
          <td width=48 valign=top style='width:.5in;border-top:none;border-left:
          none;border-bottom:solid darkgray 1.0pt;border-right:solid darkgray 1.0pt;
          mso-border-top-alt:solid darkgray .25pt;mso-border-left-alt:solid darkgray .25pt;
          mso-border-alt:solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:15;mso-yfti-lastrow:yes;height:15.1pt'>
          <td width=36 valign=top style='width:27.0pt;border:solid darkgray 1.0pt;
          border-top:none;mso-border-top-alt:solid darkgray .25pt;mso-border-alt:
          solid darkgray .25pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=1022 colspan=14 valign=top style='width:766.6pt;border-top:
          none;border-left:none;border-bottom:solid darkgray 1.0pt;border-right:
          solid darkgray 1.0pt;mso-border-top-alt:solid darkgray .25pt;
          mso-border-left-alt:solid darkgray .25pt;mso-border-alt:solid darkgray .25pt;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Number of Requests Completed More than 365 days After
          Due Date: {{periodrequests.length}}<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         </tbody>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:2;height:4.0pt'>
        <td width=1058 colspan=2 style='width:793.6pt;padding:0in 0in 0in 0in;
        height:4.0pt;min-width: 279.93mm'></td>
       </tr>
       <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes;height:9.0pt'>
        <td width=336 valign=top style='width:252.05pt;padding:0in 0in 0in 0in;
        height:9.0pt;min-width: 88.90mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=336 style='width:252.05pt;mso-cellspacing:0in;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 88.90mm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:9.0pt'>
          <td width=336 style='width:252.05pt;padding:0in 0in 0in 0in;
          height:9.0pt;min-width: 88.90mm'>
          <p class=MsoNormal><span style='font-size:1.0pt;mso-fareast-font-family:
          "Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
        <td width=722 valign=top style='width:541.55pt;padding:0in 0in 0in 0in;
        height:9.0pt;min-width: 191.03mm'></td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in;min-width: 50.80mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     width=1058 style='width:793.6pt;border-collapse:collapse;mso-yfti-tbllook:
     1184;mso-padding-alt:0in 0in 0in 0in' cols=3>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:27.0pt'>
      <td width=192 style='width:2.0in;padding:0in 0in 0in 0in;height:27.0pt'></td>
      <td width=672 style='width:504.05pt;padding:0in 0in 0in 0in;height:27.0pt;
      min-width: 177.80mm'></td>
      <td width=194 style='width:145.5pt;padding:0in 0in 0in 0in;height:27.0pt;
      min-width: 51.33mm'></td>
     </tr>
     <tr style='mso-yfti-irow:1;height:9.0pt'>
      <td width=192 valign=top style='width:2.0in;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 50.80mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal><b><i><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>{{dateTo}}<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=672 valign=top style='width:504.05pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 177.80mm'></td>
      <td width=194 valign=top style='width:145.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 51.33mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Page 2 / 3<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
     <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:9.0pt'>
      <td width=864 colspan=2 valign=top style='width:648.1pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 228.60mm'></td>
      <td width=194 valign=top style='width:145.5pt;padding:0in 0in 0in 0in;
      height:9.0pt;min-width: 51.33mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:9.0pt'>
        <td width=192 valign=top style='width:2.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:9.0pt;min-width: 50.80mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><i><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Axcess-1<o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

</div>

</div>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>



<table v-if="report_type=='DC'" class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 style='border-collapse:collapse'>
 <tr>
  <td width=1050 valign=top style='width:787.5pt;padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='border-collapse:collapse;border:none'>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border-top:solid black 1.0pt;
    border-left:solid black 1.0pt;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=13 valign=top style='width:99.0pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><i><span style='font-size:14.0pt;color:black'>Detailed
    Listing of Completed Requests: {{dateFrom}} - {{dateTo}}</span></i></b></p>
    </td>
   </tr>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border-top:solid black 1.0pt;
    border-left:solid black 1.0pt;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 valign=top style='width:99.0pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=76 valign=top style='width:57.0pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=180 valign=top style='width:135.0pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=77 valign=top style='width:57.7pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=50 colspan=2 valign=top style='width:37.45pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:solid silver 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Days</span></i></b></p>
    </td>
    <td width=39 valign=top style='width:29.25pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=37 valign=top style='width:27.75pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=144 valign=top style='width:1.5in;border-top:solid black 1.0pt;
    border-left:none;border-bottom:none;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=49 colspan=2 valign=top style='width:36.75pt;border-top:solid black 1.0pt;
    border-left:none;border-bottom:solid darkgray 1.0pt;border-right:solid black 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Pages</span></i></b></p>
    </td>
   </tr>
   <tr style='height:11.2pt'>
    <td width=36 valign=top style='width:27.0pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 valign=top style='width:99.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Request</span></i></b></p>
    </td>
    <td width=76 valign=top style='width:57.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Source</span></i></b></p>
    </td>
    <td width=180 valign=top style='width:135.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Summary</span></i></b></p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Date Complete Received</span></i></b></p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Due Date</span></i></b></p>
    </td>
    <td width=77 valign=top style='width:57.7pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Date Closed</span></i></b></p>
    </td>
    <td width=50 valign=top style='width:37.45pt;border:none;border-bottom:
    solid black 1.0pt;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Allowed</span></i></b></p>
    </td>
    <td width=43 valign=top style='width:32.25pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Taken</span></i></b></p>
    </td>
    <td width=39 valign=top style='width:29.25pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Exe</span></i></b></p>
    </td>
    <td width=37 valign=top style='width:27.75pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Exc</span></i></b></p>
    </td>
    <td width=144 valign=top style='width:1.5in;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid silver 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Disposition</span></i></b></p>
    </td>
    <td width=49 valign=top style='width:36.75pt;border:none;border-bottom:
    solid black 1.0pt;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Review</span></i></b></p>
    </td>
    <td width=45 valign=top style='width:33.75pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><b><i><span style='font-size:
    8.0pt;color:black'>Release</span></i></b></p>
    </td>
   </tr>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border:none;border-bottom:solid black 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=13 valign=top style='width:99.0pt;border:none;
    border-bottom:solid black 1.0pt;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><i><span style='color:black'>Requests Completed On
    Time</span></i></b></p>
    </td>
   </tr>
   <tr v-for="(i) in periodrequests" :key="i" style='height:11.2pt'>
    <td width=36 valign=top style='width:27.0pt;border-top:none;border-left:
    solid darkgray 1.0pt;border-bottom:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{periodrequests.indexOf(i)+1}}</span></p>
    </td>
    <td width=132 valign=top style='width:99.0pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
    color:black'>{{i.RequestDemande}}</span></p>
    </td>
    <td width=76 valign=top style='width:57.0pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
    color:black'>{{i.sourceE}}</span></p>
    </td>
    <td width=180 valign=top style='width:135.0pt;border:none;border-right:
    solid darkgray 1.0pt;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='border-collapse:collapse'>
     <tr style='height:11.1pt'>
      <td width=175 valign=top style='width:131.0pt;padding:0in 0in 0in 0in;
      height:11.1pt'>
      <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
      line-height:normal'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
      color:black'>{{i.summary}}</span></p>
      </td>
     </tr>
    </table>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'></p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.dateinfocomplete}}</span></p>
    </td>
    <td width=71 valign=top style='width:53.2pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.datedue}}</span></p>
    </td>
    <td width=77 valign=top style='width:57.7pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.dateofclosure}}</span></p>
    </td>
    <td width=50 valign=top style='width:37.45pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.allowed_new}}</span></p>
    </td>
    <td width=43 valign=top style='width:32.25pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.taken_new}}</span></p>
    </td>
    <td width=39 valign=top style='width:29.25pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.Exe}}</span></p>
    </td>
    <td width=37 valign=top style='width:27.75pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=center style='margin-bottom:0in;margin-bottom:
    .0001pt;text-align:center;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.exc}}</span></p>
    </td>
    <td width=144 valign=top style='width:1.5in;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='border-collapse:collapse'>
     <tr style='height:11.1pt'>
      <td width=139 valign=top style='width:104.0pt;padding:0in 0in 0in 0in;
      height:11.1pt'>
      <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
      line-height:normal'><span style='font-size:8.0pt;font-family:"Arial",sans-serif;
      color:black'>{{i.DispositionE}}</span></p>
      </td>
     </tr>
    </table>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'></p>
    </td>
    <td width=49 valign=top style='width:36.75pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.pagesreviewed}}</span></p>
    </td>
    <td width=45 valign=top style='width:33.75pt;border:none;border-right:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><span style='font-size:8.0pt;
    font-family:"Arial",sans-serif;color:black'>{{i.pagesreleased}}</span></p>
    </td>
   </tr>
   <tr style='height:11.2pt'>
    <td width=36 valign=top style='width:27.0pt;border:solid darkgray 1.0pt;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=11 valign=top style='width:99.0pt;border:solid darkgray 1.0pt;
    border-left:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><i><span style='color:black'>Number of Requests
    Completed On Time: 11</span></i></b></p>
    </td>
    <td width=49 valign=top style='width:36.75pt;border:solid darkgray 1.0pt;
    border-left:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><i><span style='color:black'>1,752</span></i></b></p>
    </td>
    <td width=45 valign=top style='width:33.75pt;border:solid darkgray 1.0pt;
    border-left:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;height:11.2pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><i><span style='color:black'>404</span></i></b></p>
    </td>
   </tr>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=3 valign=top style='width:99.0pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><span style='color:black'>Total Requests Completed
    After Due Date: </span></b></p>
    </td>
    <td width=71 colspan=2 valign=top style='width:53.2pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><b><span style='color:black'>0</span></b></p>
    </td>
    <td width=77 colspan=8 valign=top style='width:57.7pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
   </tr>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=3 valign=top style='width:99.0pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><span style='color:black'>Total Requests: </span></b></p>
    </td>
    <td width=71 colspan=2 valign=top style='width:53.2pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><b><span style='color:black'>{{periodrequests.length}}</span></b></p>
    </td>
    <td width=77 valign=top style='width:57.7pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=50 valign=top style='width:37.45pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=43 valign=top style='width:32.25pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=39 valign=top style='width:29.25pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=37 valign=top style='width:27.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=144 valign=top style='width:1.5in;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=49 valign=top style='width:36.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=45 valign=top style='width:33.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
   </tr>
   <tr style='height:14.1pt'>
    <td width=36 valign=top style='width:27.0pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=132 colspan=3 valign=top style='width:99.0pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'><b><span style='color:black'>Total Pages
    Reviewed/Released: </span></b></p>
    </td>
    <td width=71 colspan=2 valign=top style='width:53.2pt;border:none;
    padding:1.95pt 1.95pt 1.95pt 1.95pt;height:14.1pt'>
    <p class=MsoNormal align=right style='margin-bottom:0in;margin-bottom:.0001pt;
    text-align:right;line-height:normal'><b><span style='color:black'>1,752 /
    404</span></b></p>
    </td>
    <td width=77 valign=top style='width:57.7pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=50 valign=top style='width:37.45pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=43 valign=top style='width:32.25pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=39 valign=top style='width:29.25pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=37 valign=top style='width:27.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=144 valign=top style='width:1.5in;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=49 valign=top style='width:36.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
    <td width=45 valign=top style='width:33.75pt;border:none;padding:1.95pt 1.95pt 1.95pt 1.95pt;
    height:14.1pt'>
    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;
    line-height:normal'>&nbsp;</p>
    </td>
   </tr>
  </table>
  <p class=MsoNormal style='margin-bottom:0in;margin-bottom:.0001pt;line-height:
  normal'></p>
  </td>
  <td width=8 valign=top style='width:5.95pt;padding:0in 0in 0in 0in'>
  <p class=EmptyCellLayoutStyle style='margin-bottom:0in;margin-bottom:.0001pt;
  line-height:normal'>&nbsp;</p>
  </td>
 </tr>
</table>



</span></div>

</div>




        </v-col>
        </v-row>

    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>


<script>

import DatePicker from "../components/DatePicker";
import { mapState,mapActions } from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {

  components: {
 DatePicker,

  },
  data(){
    return {
      flag:0,
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      report_types:[
        { id:'DX',name:"Active Requests"},
        { id:'DC',name:"Closed Requests"},

      ],
      report_type:"DC",
      request_type:"%",
      periodrequests:[],
      T1: [
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
       [0,0,0,0],
    ] ,
    malek:0,
    T2:[]
    };
  },
  methods:{
      ...mapActions(["retriveTypes"]),
    selected(){

    },

     groupBy(key) {
    return function group(array) {
    return array.reduce((acc, obj) => {
      const property = obj[key];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  };
},

    generateReport() {
       this.flag = 1;
      let data = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        report_type:this.report_type,
        request_type:this.request_type
      }
  // alert(this.request_types.filter(o => o.id==this.request_type)[0].description_en);
 //  alert(this.report_types.filter(o => o.id==this.report_type)[0].name);
      const response =axcessApi.postRequest("/report/TimeAnalysis",data);
      response.then(output => {
       this.periodrequests=output.data.periodrequests;
            this.malek="";
         for (let i of this.periodrequests) {
           if(i.ActiveWithinBeyond=="On Time")
            this.T1[0][0]++;
           if(i.ActiveWithinBeyond=="1 - 15 days")
            this.T1[1][0]++;
           if(i.ActiveWithinBeyond=="16 - 30 days")
            this.T1[2][0]++;
           if(i.ActiveWithinBeyond=="31 - 60 days")
            this.T1[3][0]++;
           if(i.ActiveWithinBeyond=="61 - 120 days")
            this.T1[4][0]++;
           if(i.ActiveWithinBeyond=="121 - 180 days")
            this.T1[5][0]++;
           if(i.ActiveWithinBeyond=="181 - 365 days")
            this.T1[6][0]++;
           if(i.ActiveWithinBeyond=="More than 365 days")
            this.T1[7][0]++;
            if(i.OnTimeLate=="Late")
            this.T1[8][0]++;
            this.T1[9][0]=this.T1[8][0];

            this.T1[0][1]=this.T1[0][0]/this.T1[9][0]*100;
            this.T1[1][1]=this.T1[1][0]/this.T1[9][0]*100;
            this.T1[2][1]=this.T1[2][0]/this.T1[9][0]*100;
            this.T1[3][1]=this.T1[3][0]/this.T1[9][0]*100;
            this.T1[4][1]=this.T1[4][0]/this.T1[9][0]*100;
            this.T1[5][1]=this.T1[5][0]/this.T1[9][0]*100;
            this.T1[6][1]=this.T1[6][0]/this.T1[9][0]*100;
            this.T1[7][1]=this.T1[7][0]/this.T1[9][0]*100;
            this.T1[8][1]=this.T1[8][0]/this.T1[9][0]*100;
            this.T1[9][1]=this.T1[9][0]/this.T1[9][0]*100;

         if(i.ActiveWithinBeyond=="On Time")
            this.T1[0][2]++;
           if(i.ActiveWithinBeyond=="1 - 15 days")
            this.T1[1][2]++;
           if(i.ActiveWithinBeyond=="16 - 30 days")
            this.T1[2][2]++;
           if(i.ActiveWithinBeyond=="31 - 60 days")
            this.T1[3][2]++;
           if(i.ActiveWithinBeyond=="61 - 120 days")
            this.T1[4][2]++;
           if(i.ActiveWithinBeyond=="121 - 180 days")
            this.T1[5][2]++;
           if(i.ActiveWithinBeyond=="181 - 365 days")
            this.T1[6][2]++;
           if(i.ActiveWithinBeyond=="More than 365 days")
            this.T1[7][2]++;
            if(i.OnTimeLate=="Late")
            this.T1[8][2]++;
            this.T1[9][2]=this.T1[8][2];

            this.T1[0][3]=this.T1[0][2]/this.T1[9][2]*100;
            this.T1[1][3]=this.T1[1][2]/this.T1[9][2]*100;
            this.T1[2][3]=this.T1[2][2]/this.T1[9][2]*100;
            this.T1[3][3]=this.T1[3][2]/this.T1[9][2]*100;
            this.T1[4][3]=this.T1[4][2]/this.T1[9][2]*100;
            this.T1[5][3]=this.T1[5][2]/this.T1[9][2]*100;
            this.T1[6][3]=this.T1[6][2]/this.T1[9][2]*100;
            this.T1[7][3]=this.T1[7][2]/this.T1[9][2]*100;
            this.T1[8][3]=this.T1[8][2]/this.T1[9][2]*100;
            this.T1[9][3]=this.T1[9][2]/this.T1[9][2]*100;

         }   

         const groupByType = this.groupBy("OnTimeLate");
        this.T2=groupByType(this.periodrequests);
        console.log("ontime late",this.T2);
      })
    },

    getLookup(column){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },

    closeReport(){
      this.flag = 0;
    }
  },
   computed: {

   ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),

      ...mapState({
      request_types: "types",
    }),
   },
     async created(){
    await this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("configuration/retriveConfigChoices");
    this.request_types.unshift({description_en:"All Request Types",id:"%"});
    
    },
    mounted () {
         this.retriveTypes();
    },
  
};

</script>